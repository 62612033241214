import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "examples/Breadcrumbs";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const route = useLocation().pathname.split("/").slice(1);
  const role = localStorage.getItem("userRole");

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    // Dummy notifications
    setNotifications([
      { id: 1, message: "New message from John" },
      { id: 2, message: "Your profile was updated" },
      { id: 3, message: "New comment on your post" },
    ]);

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenNotifications = (event) => {
    setOpenNotifications(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  };

  const handleProfileClick = () => {
    if (role === "USER") {
      navigate("/profile");
    } else if (role === "ADMIN") {
      navigate("/admin");
    }
    handleCloseMenu();
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/authentication/sign-in");
    handleCloseMenu();
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={handleProfileClick}>
        <Icon>account_circle</Icon> Profile
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Icon>logout</Icon> Log out
      </MenuItem>
    </Menu>
  );

  const renderNotifications = () => (
    <Menu
      anchorEl={openNotifications}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(openNotifications)}
      onClose={handleCloseNotifications}
      sx={{ mt: 2 }}
    >
      <MDBox sx={{ width: "300px" }}>
        <Typography variant="h6" sx={{ padding: 1 }}>
          Notifications
        </Typography>
        <Divider />
        {notifications.map((notification) => (
          <MenuItem key={notification.id} onClick={handleCloseNotifications}>
            {notification.message}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/notifications");
            handleCloseNotifications();
          }}
        >
          <Typography color="primary" sx={{ textAlign: "center" }}>
            Show All Notifications
          </Typography>
        </MenuItem>
      </MDBox>
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton onClick={handleOpenNotifications} size="small" disableRipple>
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleOpenMenu}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton size="small" disableRipple color="inherit" onClick={handleFullScreen}>
                <Icon className="feather feather-maximize" sx={iconsStyle} />
              </IconButton>
              {renderMenu()}
              {renderNotifications()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
