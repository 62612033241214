import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Typography from "@mui/material/Typography";

function Information() {
  return (
    <MKBox component="section" py={12} mt={-2}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          {" "}
          <Grid item xs={12}>
            <MKBox mb={5} textAlign="center">
              {" "}
              <Typography variant="h4" component="h1" fontSize={50}>
                Features
              </Typography>
              <Typography variant="body2" component="p" sx={{ mt: 1 }}>
                This platform encompasses a diverse array of features that underscore its pivotal
                role in advancing the field of pharmacology. By fostering a collaborative
                environment for researchers, clinicians, and industry professionals, we promote
                innovation and excellence in drug development and therapeutic practices. Together,
                we aim to empower the global pharmacological community, driving progress and
                improving patient outcomes through cutting-edge knowledge and shared expertise
              </Typography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="assignment"
                title="Peer-Reviewed Articles"
                description="High-quality research articles reviewed by experts in the field to ensure validity and 
                relevance."
                action={{
                  type: "internal",
                  route: "/peer-reviewed",
                  label: "Explore Articles",
                  color: "primary",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="article"
                title="Original Research Paper"
                description="Detailed studies on drug development, efficacy, safety, pharmacokinetics, pharmacodynamics,
                 and therapeutic applications."
                action={{
                  type: "internal",
                  route: "/original-research",
                  label: "Read Research",
                  color: "success",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="fact_check"
                title="Review Articles"
                description="Comprehensive reviews that summarize current knowledge, trends, 
                and future directions in drug research."
                action={{
                  type: "internal",
                  route: "/review-articles",
                  label: "View Reviews",
                  color: "info",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
