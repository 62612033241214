import { Card, Grid, Avatar } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import PropTypes from "prop-types";

function EditorDashboard({ articleCount, verifiedArticleCount }) {
  const cardData = [
    {
      title: "Total Assigned Documents",
      count: articleCount,
      color: "#e8f5e9",
      avatarColor: "#9c27b0",
    },
    {
      title: "Verified Documents",
      count: verifiedArticleCount,
      color: "#fff3e0",
      avatarColor: "#4caf50",
    },
  ];

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h4" color="white" align="center">
                Dashboard
              </MDTypography>
            </MDBox>

            <MDBox pt={2} px={2} mt={4} mb={2}>
              <Grid container spacing={3}>
                {cardData.map((card, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card sx={{ backgroundColor: card.color, height: "100%" }}>
                      <MDBox p={2}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {card.title}
                        </MDTypography>
                        <MDTypography variant="h3" fontWeight="bold">
                          {card.count}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" p={1}>
                        <Avatar sx={{ bgcolor: card.avatarColor }}>
                          {getIconByTitle(card.title)}
                        </Avatar>
                      </MDBox>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditorDashboard.propTypes = {
  articleCount: PropTypes.number.isRequired,
  verifiedArticleCount: PropTypes.number.isRequired,
};

// Get icon based on the card title
const getIconByTitle = (title) => {
  switch (title) {
    case "Total Assigned Documents":
      return <LibraryBooksIcon />;
    case "Verified Documents":
      return <CheckCircleIcon />;
    default:
      return <PublishedWithChangesIcon />;
  }
};

export default EditorDashboard;
