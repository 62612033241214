import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Button from "@mui/material/Button";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditorForm from "./EditorForm"; // Import EditorForm
import { fetchUsers, deleteUser } from "layouts/editorslist/data/fetchUsers"; // Import deleteUser function

const EditorList = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Track whether the form modal is open

  // Fetch users
  const fetchUsersData = async () => {
    setLoading(true);
    try {
      const response = await fetchUsers();
      setUsers(response.data); // Set users after fetching
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersData(); // Initial fetch on component mount
  }, []);

  // Handle opening form for adding a new editor
  const handleAdd = () => {
    setEditingUser(null); // No user for adding new
    setShowForm(true); // Show form
  };

  // Handle opening form for editing an editor
  const handleEdit = (id) => {
    const userToEdit = users.find((user) => user.id === id);
    setEditingUser(userToEdit); // Set user for editing
    setShowForm(true); // Show form
  };

  // Handle deleting a user
  const handleDelete = async (id) => {
    try {
      console.log("Attempting to delete user with id:", id); // Debugging line
      await deleteUser(id); // Call deleteUser function
      console.log("User deleted successfully, fetching updated users..."); // Debugging line
      fetchUsersData(); // Re-fetch users to update the list
    } catch (error) {
      console.error("Error deleting user:", error); // Log any errors
    }
  };

  // Function to refresh the user list after adding or editing an editor
  const handleUserCreatedOrUpdated = () => {
    fetchUsersData(); // Refresh user list after add/edit
    setShowForm(false); // Close the form modal
  };

  // Data table columns
  const columns = [
    { Header: "Name", accessor: "name", isSorted: true },
    { Header: "Email", accessor: "email", isSorted: true },
    { Header: "Contact", accessor: "contact", isSorted: true },
    { Header: "Address", accessor: "address", isSorted: true },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div>
          <Button onClick={() => handleEdit(row.original.id)} sx={{ marginRight: 1 }}>
            <FaEdit />
          </Button>
          <Button onClick={() => handleDelete(row.original.id)} sx={{ color: "red" }}>
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  // Filter users for editors only
  const rows = users
    .filter((user) => user.role === "EDITOR")
    .map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      contact: user.contact,
      address: user.address,
    }));

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h4" color="white" align="center">
                Editors Overview
              </MDTypography>
            </MDBox>

            {/* Add Button Positioned at the Top */}
            <MDBox display="flex" justifyContent="right" sx={{ padding: 2 }}>
              <Button
                onClick={handleAdd}
                variant="outlined"
                color="info"
                sx={{
                  marginRight: 1,
                  borderColor: "info.main",
                  color: "info.main",
                  "&:hover": {
                    backgroundColor: "info.light",
                    borderColor: "info.dark",
                  },
                }}
                startIcon={<FaPlus />}
              >
                Add Editor
              </Button>
            </MDBox>

            <MDBox pt={3} mb={3}>
              {loading ? (
                <MDTypography align="center" variant="h6">
                  Loading users...
                </MDTypography>
              ) : rows.length > 0 ? (
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={4}
                >
                  <MDTypography variant="h5" color="textSecondary" align="center" mb={2}>
                    <FaEdit style={{ fontSize: "40px", marginBottom: "10px" }} />
                    No Editors Found
                  </MDTypography>
                  <MDTypography variant="body1" color="textSecondary" align="center" mb={3}>
                    It looks like you don&apos;t have any editors listed yet. Add one to get
                    started.
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      {/* Editor Form Modal */}
      {showForm && (
        <EditorForm
          user={editingUser}
          onClose={() => setShowForm(false)} // Close the form modal
          onUserCreatedOrUpdated={handleUserCreatedOrUpdated} // Refresh user list after add/edit
        />
      )}
    </>
  );
};

EditorList.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default EditorList;
