// src/services/articleService.js
import axios from "axios";

// Base URL for the API (you can configure it later)
const BASE_URL = "https://server.ayurdrishti.com/api";

// Fetch all article details
export const fetchArticleDetails = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/article-details`);
    return response.data; // Returning the response data (articles)
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error; // You can handle the error properly in your components later
  }
};

// Update article details (verified, paymentStatus, or published)
export const updateArticleDetails = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}/article-details/${id}`, updatedData);
    return response.data; // Returning updated article data
  } catch (error) {
    console.error("Error updating article details:", error);
    throw error;
  }
};

// Download article file
export const downloadArticleFile = async (fileName) => {
  try {
    const response = await axios.get(`${BASE_URL}/file/${fileName}`, {
      responseType: "blob", // Important for file download
    });
    return response.data; // Return the blob data
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
