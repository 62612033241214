// PaymentService.js
import axios from "axios";

//const baseUrl = "https://phonepe-render-tt.onrender.com";
const baseUrl = "https://server.ayurdrishti.com";

const sendPaymentRequest = async (paymentRequest) => {
  try {
    const response = await axios.post(`${baseUrl}/api/sendPaymentRequest`, paymentRequest, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // The URL returned from the server
  } catch (error) {
    console.error("Error occurred:", error);
    throw error;
  }
};

export default sendPaymentRequest;
