import axios from "axios";

const BASE_URL = "https://server.ayurdrishti.com/api";

export const fetchArticleDetails = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/article-details/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

export const updateArticleDetails = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}/article-details/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error updating article details:", error);
    throw error;
  }
};

export const downloadArticleFile = async (fileName) => {
  try {
    const response = await axios.get(`${BASE_URL}/file/${fileName}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
