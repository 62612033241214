import axios from "axios";

// Get the token from local storage
const token = localStorage.getItem("csrfToken");
console.log("Retrieved token:", token); // Debug log to ensure token is retrieved

// Create an axios instance with the base URL and Authorization header
const axiosInstance = axios.create({
  baseURL: "https://server.ayurdrishti.com/api",
  headers: {
    Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
    "Content-Type": "application/json",
  },
});

// Intercept response to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized access - token might be expired or invalid.");
      // Optional: redirect to login or refresh token logic
    }
    return Promise.reject(error);
  }
);

// API Calls (fetch, create, update, delete)
const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get("/users");
    return response;
  } catch (error) {
    console.error("Error fetching users", error);
    throw error;
  }
};

const createUser = async (user) => {
  try {
    const response = await axiosInstance.post("/users", user);
    return response.data;
  } catch (error) {
    console.error("Error saving user:", error);
    throw error;
  }
};

const updateUser = async (id, user) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, user); // Use backticks for string interpolation
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`); // Correct base URL, now using axiosInstance
    console.log("Delete response:", response); // Debugging line
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error; // Ensure the error is thrown for the caller to handle
  }
};

export { fetchUsers, createUser, updateUser, deleteUser };
