import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import { useMaterialUIController } from "context";
import logo from "assets/images/WhatsApp Image 2024-10-10 at 10.53.39 AM.png";

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const navigate = useNavigate();

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  // Get user role from localStorage
  const userRole = localStorage.getItem("userRole") || "GUEST";
  const profileRoute =
    userRole === "ADMIN" ? "/admin" : userRole === "EDITOR" ? "/editorprofile" : "/profile";

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();

    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        width="100%"
        borderRadius="30px"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"} // Change color to "red" for light mode
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        right={0}
        zIndex={3}
        sx={(theme) => ({
          backgroundColor: transparent
            ? theme.palette.transparent.main
            : theme.functions.rgba(
                darkMode ? theme.palette.background.sidenav : theme.palette.white.main,
                -7.9
              ),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        {/* Left side - Logo */}
        <MDBox display="flex" alignItems="center" flexShrink={0}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "90px", marginRight: "8px" }}
          />
        </MDBox>

        {/* Right side - Links and Buttons */}
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={1}
          flexWrap="wrap"
          color="info"
        >
          {/* Navbar links */}
          <MDBox color="info" display={{ xs: "none", lg: "flex" }} m={0} p={0} alignItems="center">
            <DefaultNavbarLink icon="home" name="Home" route="/abouthome" light={light} />
            <DefaultNavbarLink icon="info" name="About Us" route="/about" light={light} />
            <DefaultNavbarLink
              icon="person"
              name="Editorial Team"
              route="/editorteam"
              light={light}
            />
            <DefaultNavbarLink icon="person" name="Peer Team" route="/peerteam" light={light} />
            <DefaultNavbarLink
              icon="person"
              name="Author Guidelines"
              route="/authorguideline"
              light={light}
            />
            {/* <DefaultNavbarLink icon="mail" name="Contact Us" route="/contact" light={light} /> */}
            <DefaultNavbarLink icon="article" name="Articles" route="/articles" light={light} />
          </MDBox>

          {/* Action Button */}
          {action && (
            <MDBox display={{ xs: "none", lg: "flex" }} ml={2}>
              {action.type === "internal" ? (
                <MDButton
                  component={Link}
                  to={action.route}
                  variant="gradient"
                  color={action.color || "info"}
                  size="small"
                >
                  {action.label}
                </MDButton>
              ) : (
                <MDButton
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color={action.color || "info"}
                  size="small"
                >
                  {action.label}
                </MDButton>
              )}
            </MDBox>
          )}

          {/* Login Button */}
          <MDBox ml={2}>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => navigate("/authentication/sign-in")}
            >
              Login
            </MDButton>
          </MDBox>
        </MDBox>

        {/* Mobile Menu Icon */}
        <MDBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MDBox>
      </MDBox>

      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
