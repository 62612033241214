import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CustomAuthorCard from "examples/Cards/CustomAuthorCard";
import consortGuideline from "assets/drpdf/consort_guidline.pdf";
import copyrightForm from "assets/drpdf/copyright_form_2.pdf";
import careChecklist from "assets/drpdf/CARE_checklist_for case report.pdf";
import strobeChecklistCohort from "assets/drpdf/strobe_checklist_cohort.pdf";
import strobeChecklistCaseControl from "assets/drpdf/strobe_checklist_case_control.pdf";
import strobeChecklistCrossSectional from "assets/drpdf/strobe_checklist_cross_sectional.pdf";

function Information() {
  const [selectedContent, setSelectedContent] = useState("Aim & Scope");
  const contentDetails = {
    "Aim & Scope": (
      <>
        <MKTypography variant="h3" color="#00a65a" gutterBottom>
          Aim & Scope
        </MKTypography>
        <MKTypography variant="body2" sx={{ opacity: 0.8 }}>
          <strong>AYURDRISHTI</strong> is an international peer-reviewed, quarterly open-access
          journal publishing online versions. AYURDRISHTI focuses on the publication of current
          research carried out under Ayurveda, Yoga, Unani, Siddha, Homeopathy, Modern Medical
          Systems, and Pharmaceutical Sciences. The journal covers drug research, health system
          innovation, traditional Indian medicines, Ayurveda clinical trials, case studies,
          observational studies, meta-analysis, and in vitro/in vivo studies.
          <br />
          <br />
          <strong>AYURDRISHTI</strong> publishes the following types of manuscripts:
          <ol>
            <li>Original Research Articles</li>
            <li>Review Articles</li>
            <li>Book Reviews</li>
            <li>Short Communications/Research Letters</li>
            <li>Case Studies</li>
          </ol>
        </MKTypography>
      </>
    ),
    "Manuscript Preparation": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Manuscript Preparation
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          <ol>
            <li>The manuscript should be prepared in English.</li>
            <li>Page Layout – A4 Size.</li>
            <li>Orientation - Portrait Mode.</li>
            <li>Margin - 1 inch margin on all sides (Top, Bottom, Left, and Right).</li>
            <li>No header or footer.</li>
            <li>Font – Times New Roman.</li>
            <li>
              Font size –
              <ul>
                <li>Title: 16pt, bold, sentence case.</li>
                <li>Main headings: 11pt, bold, sentence case.</li>
                <li>Subheadings: 10pt, bold, sentence case.</li>
              </ul>
            </li>
            <li>Spacing – 1.0.</li>
            <li>
              Insert Page Number - at the bottom extreme right side of the page (format: 1, 2,
              3,...).
            </li>
            <li>Paragraph orientation - Justify.</li>
            <li>
              No underlines. Sanskrit words should be in italics. No abbreviations or acronyms shall
              be used in the titles or abstract, except for measurements. There shall be no
              decorative borders anywhere in the text, including the title page. Tables and charts
              should be clearly titled at the top.
            </li>
          </ol>
        </MKTypography>
      </>
    ),
    "Sequence for Manuscripts Submission": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Sequence for Manuscripts Submission
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          <ol>
            <li>Title Page</li>
            <li>Authors and Co-Authors details and their affiliations</li>
            <li>Abstract (150 to 200 words)</li>
            <li>Keywords</li>
            <li>Introduction</li>
            <li>Materials and Methods</li>
            <li>Results and Discussion</li>
            <li>Conclusion</li>
            <li>Acknowledgment</li>
            <li>References</li>
          </ol>
        </MKTypography>
      </>
    ),
    "Original Research Articles": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Original Research Articles
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Original research papers should present new experimental studies in an elaborate form that
          constitutes a significant contribution to knowledge and should not exceed 6 pages. A
          research paper should be arranged into the following sequence:
          <br />
          <br />
          <strong>Title:</strong>
          All manuscripts should start with a title page. The title should be as short as possible,
          providing precise information about the contents, followed by the names of authors,
          affiliations, and institutional addresses. The title should fulfill all research criteria.
          Only authentic abbreviations are accepted. Nonscientific words are not allowed.
          <br />
          <br />
          <strong>Author Names:</strong>
          All author names must provide their full names (surnames first) and be mentioned below the
          title. Designations of the authors, institute name (if any), residential city/town, state,
          country, followed by mobile number and email, should be included. The corresponding author
          of the manuscript must be in the second line, marked with numbers starting with 2nd, 3rd,
          etc. In addition, the corresponding author must indicate their residential city/town,
          state, country, followed by mobile number and email.
          <br />
          <strong>Example:</strong>
          Dr. Sharma Dilipkumar Ramesh, Professor, Dept. of Kayachikitsa, Ramdayal Medical
          Institute, Amaravati, Maharashtra, India, 8**1123231, xyz@gmail.com, Dr. Mishra Amit
          Radhesham, Asso. Professor, Dept. of Kayachikitsa, Ramdayal Medical Institute, Amaravati,
          Maharashtra, India, 8**0123231, abxyzz@gmail.com
          <br />
          <br />
          <strong>Abstract:</strong>
          The abstract should not exceed 200 words and be informative, completely self-explanatory.
          It should briefly present the topic, state the scope of the experiments, indicate
          significant data, important observations, and conclusions. Standard nomenclature should be
          used; abbreviations and references should be avoided.
          <br />
          <br />
          <strong>Keywords:</strong>
          All manuscripts should include keywords reflecting the major features of the work, about
          four to six. These keywords will be used for indexing purposes.
          <br />
          <br />
          <strong>Introduction:</strong>
          The introduction should include the background of the subject, earlier works carried out,
          and signify the relationship with the proposed work along with the aims and objectives of
          this study.
          <br />
          <br />
          <strong>Materials & Methods:</strong>
          This section should be complete enough to allow experiments to be reproduced. All
          procedures should be described in detail, including diagnostic methods, eligibility
          criteria for inclusion and exclusion, methods adopted for the study (with references to
          established methods), dosage, and route of administration.
          <br />
          <br />
          <strong>Results & Discussion:</strong>
          The observations of the study must be illustrated with figures or tables wherever
          necessary. The research, with appropriate statistical analysis described in the methods
          section, should be self-explanatory.
          <br />
          <br />
          <strong>Conclusion:</strong>
          The major findings of the work should highlight its importance, relevance, and usefulness.
          The conclusions must include a short summary and further scope in the field. Repetition of
          the Results & Discussion section should be avoided.
          <br />
          <br />
          <strong>Acknowledgment:</strong>
          All acknowledgments should be typed in one paragraph directly preceding the reference
          section and may include supporting grants, presentations, etc. No personal details should
          be mentioned.
          <br />
          <br />
          <strong>References:</strong>
          References should be in Vancouver style and numbered consecutively in the order in which
          they are first mentioned in the text. Cite references in the text by the appropriate
          number with superscripts, e.g., [1], [2, 3], [4-6], [7, 8-10]. The numbers should be
          within square brackets. References cited only in tables or figure legends should be
          numbered according to their first identification in the text. Avoid using abstracts as
          references, and do not cite any references in the conclusion section.
          <br />
          <br />
          <strong>Tables:</strong>
          Tables should be typed in MS Word table format (with rows and columns visible) and be
          numbered consecutively in numerals with a brief title in capital letters. Tables must be
          inserted within the text. Abbreviations should be defined as footnotes in italics at the
          bottom of each table.
          <br />
          <br />
          <strong>Figures:</strong>
          Figures must be on separate pages in JPEG format and not inserted within the text. They
          should bear a brief title in lowercase boldface letters below the figure. Photographs,
          drawings, diagrams, and charts are to be numbered in one consecutive series in the order
          in which they are cited in the text and abbreviated as Fig. 1, Fig. 2, etc. Photos or
          drawings must have a good contrast of dark and light. Legends of figures should be brief
          but complete and self-explanatory so that the reader can easily understand the results
          presented in the figure.
        </MKTypography>
      </>
    ),
    "Review Articles": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Review Articles
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Reviews are systemic critical assessments of literature and data sources in a particular
          field. Usually, reviews are invited from researchers with considerable experience in the
          field concerned. The purpose of these manuscripts is to discuss in detail the existing
          state of knowledge, critical analysis of different opinions, and up-to-date developments
          on the subject of current interest.
        </MKTypography>
      </>
    ),
    "Book Reviews": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Book Reviews
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Introduction, Authors rsecognition, Subject matter of book at a glance, Analysis of
          particular methodologies and techniques mentioned in the book, Chapter Discussion,
          Indices, Practical aspects, Demerits of the text, Conclusion.
        </MKTypography>
      </>
    ),
    "Short Communication / Research Letter": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Short Communication / Research Letter
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Original research work that does not fulfill the criteria for a full-length research paper
          can be published as a short communication or research letter.
        </MKTypography>
      </>
    ),
    "For Short communication": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Short communication
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Prepare the manuscript exactly as described under Research Paper category.
        </MKTypography>
      </>
    ),
    "For Research Letter": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          For Research Letter
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Prepare the manuscript exactly as described under Research Paper category without any
          subheadings.
        </MKTypography>
      </>
    ),
    "Case Studies": (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Case Studies
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Clinical cases with unusual presentations of diseases, as well as unreported adverse drug
          reaction cases, can be submitted under this category. Authors must provide a statement
          that the patient described in the case report has given informed consent for its
          publication. Case reports should include an unstructured abstract, keywords, introduction,
          case report, brief discussion, conclusion, and references.
        </MKTypography>{" "}
        <MKTypography variant="h6" color="#00a65a" mt={2}>
          Plagiarism
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          AYURDRISHTI is committed to preventing plagiarism. Manuscripts will be scrutinized, and
          only those with less than 10% plagiarism will be accepted. Authors must submit a Copyright
          form. The publisher reserves the right to accept, publish, or reject manuscripts.
        </MKTypography>{" "}
        <MKTypography variant="h6" color="#00a65a" mt={2}>
          Online Submission
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          Authors can submit their manuscripts through the online submission portal only.
        </MKTypography>{" "}
        <MKTypography variant="h6" color="#00a65a" mt={2}>
          Manuscript Review Process
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          AYURDRISHTI follows a double-blind peer review policy. Submitted manuscripts are initially
          reviewed by the Chief Editor to ensure they meet journal standards. If accepted, the
          manuscript is further evaluated by the Editorial team and assigned to reviewers with
          expertise in the subject matter. Reviews assess content, relevance, language, and
          plagiarism. If reviewers provide feedback, the manuscript is returned to the author for
          revision. Authors are expected to make revisions based on the reviewers comments.
          Manuscripts may undergo multiple rounds of review before final acceptance. Confirmation of
          acceptance will be sent to the corresponding author via email. Accepted articles will be
          queued for publication in the next issue of the journal. AYURDRISHTI does not publish
          articles continuously.
        </MKTypography>
      </>
    ),
    Copyright: (
      <>
        <MKTypography variant="h3" color="#00a65a">
          Copyright
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          It is a condition of publication that manuscripts submitted to the journal have not been
          published and will not be simultaneously submitted or published elsewhere. Plagiarism is
          strictly forbidden, and by submitting the article for publication, all authors agree that
          the Chief Editor has the legal right to take appropriate action against the authors if
          plagiarism or fabricated information is discovered. The corresponding author is required
          to sign on behalf of all co-authors in the Copyright Agreement and Authorship
          Responsibility Form once the manuscript is accepted.
          <br />
          <br />
          <strong>Copyright Transfer:</strong>
          The author(s) hereby represent that they are the sole author(s) of the work, that all
          authors have participated in and agree with the content and conclusions of the work, that
          the work is original, and does not infringe upon any copyright, proprietary, or personal
          right of any third party. Additionally, no part of it, nor any work based on substantially
          similar data, has been submitted to another publication.
          <br />
          <br />
          <strong>Gallery Proofs:</strong>
          Page proofs and correction formats are sent to the corresponding author via email. It is
          the responsibility of the corresponding author to ensure that the galley proofs are
          returned within 7 days of receipt. If corrections are required, please use the correction
          format, which is attached. If there are no corrections, please give us permission by
          sending a reply email stating <b>NO corrections</b> to upload it on the website.
          <br />
          <br />
          <strong>Privacy Statement:</strong>
          The names, email addresses, and phone numbers entered on this journal site will be used
          exclusively for the stated purposes of this journal and will not be made available for any
          other purpose or to any other party.
          <br />
          <br />
          <strong>Open Access:</strong>
          AYURDRISHTI is an open access journal, and all articles are available on the internet to
          all users immediately upon publication. Non-commercial use and distribution in any medium
          are permitted, provided the author and the journal are properly credited.
          <br />
          <br />
          <strong>Benefits of Open Access for Authors Include:</strong>
          1. Free access for all users worldwide 2. Authors retain copyright to their work 3.
          Increased visibility and readership
          <br />
          <br />
          <strong>Copyright Form:</strong>
          Authors need to submit the copyright form prior to the publication of the article.
        </MKTypography>
      </>
    ),
    "Publication Charges": (
      <>
        <MKTypography variant="h3" color="#00a65a" gutterBottom>
          Publication Charges
        </MKTypography>
        <MKTypography variant="body2" opacity={0.8}>
          This journal charges the following author fees. There are no charges during the submission
          of articles. Only after the acceptance of the manuscript are authors required to pay a
          publication fee for web maintenance, processing, and editing purposes.
          <br />
          <br />
          <strong>For Indian Authors:</strong>
          - One-time registration fee for the first author: Rs. 500/- - Online publication charges:
          Rs. 1500/- per article.
          <br />
          <br />
          <strong>For Foreign Authors:</strong>
          - One-time registration fee for the first author: INR 500/- - Online publication charges:
          INR 4000/- per article.
          <br />
          <br />
          <strong>NOTE:</strong> Authors are requested to send their articles strictly according to
          the format mentioned in the guidelines for authors.
        </MKTypography>
      </>
    ),
  }; // Links array for the card
  const links = [
    { text: "Aim & Scope" },
    { text: "Manuscript Preparation" },
    { text: "Sequence for Manuscripts Submission" },
    { text: "Original Research Articles" },
    { text: "Review Articles" },
    { text: "Book Reviews" },
    { text: "Short Communication / Research Letter" },
    { text: "For Short communication" },
    { text: "For Research Letter" },
    { text: "Case Studies" },
    { text: "Copyright" },
    { text: "Publication Charges" },
  ];
  const downloadResources = [
    { text: "Consort Guideline", size: "736.1 kB", url: consortGuideline },
    { text: "Copyright Form", size: "151.1 kB", url: copyrightForm },
    { text: "Care Checklist for Case Report", size: "621.1 kB", url: careChecklist },
    { text: "Strobe Checklist for Cohort", size: "387.1 kB", url: strobeChecklistCohort },
    {
      text: "Strobe Checklist for Case Control",
      size: "388.1 kB",
      url: strobeChecklistCaseControl,
    },
    {
      text: "Strobe Checklist for Cross Sectional",
      size: "387.1 kB",
      url: strobeChecklistCrossSectional,
    },
  ];
  // Handle link click to show the corresponding content
  const handleLinkClick = (text) => {
    setSelectedContent(text);
  };

  return (
    <MKBox component="section" variant="gradient" bgColor="light" py={6} px={{ xs: 2, lg: 0 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <MKTypography variant="h3" color="textSecondary">
              Author Guidelines
            </MKTypography>
            <MKBox mt={4} mr={2}>
              {contentDetails[selectedContent]}
            </MKBox>

            <MKTypography variant="h3" gutterBottom mt={4}>
              Download Resources
            </MKTypography>
            <MKBox display="flex" flexDirection="column" gap={2}>
              {downloadResources.map((resource, index) => (
                <MKBox
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <MKTypography variant="body2">{resource.text}</MKTypography>
                  <MKTypography variant="body2" color="textSecondary">
                    {resource.size}{" "}
                    <a
                      href={resource.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#388e3c" }}
                    >
                      View
                    </a>
                  </MKTypography>
                </MKBox>
              ))}
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox>
              <CustomAuthorCard
                links={links.map((link) => ({
                  text: link.text,
                  url: "#",
                  onClick: () => handleLinkClick(link.text),
                }))}
              />
            </MKBox>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
