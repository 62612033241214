// EditorForm.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { createUser, updateUser } from "layouts/editorslist/data/fetchUsers";

const EditorForm = ({ user, onClose, onUserCreatedOrUpdated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    password: "",
    role: "EDITOR",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        contact: user.contact,
        address: user.address,
        password: "", // Clear password on edit
        role: user.role,
      });
    }
  }, [user]);

  const validateForm = () => {
    const errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.contact) errors.contact = "Contact is required";
    else if (!/^\d{10}$/.test(formData.contact)) {
      errors.contact = "Contact must be exactly 10 digits";
    }
    if (!formData.address) errors.address = "Address is required";
    if (!formData.password) errors.password = "Password is required";
    else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      if (user) {
        await updateUser(user.id, formData); // Update user
      } else {
        await createUser(formData); // Create new user
      }
      onUserCreatedOrUpdated(); // Refresh user list after add/edit
      onClose(); // Close form modal
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        zIndex: 1000,
        width: "90%",
        maxWidth: "400px",
      }}
    >
      <h5>{user ? "Edit Editor" : "Add Editor"}</h5>
      <TextField
        label="Name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        label="Contact"
        value={formData.contact}
        onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.contact}
        helperText={errors.contact}
      />
      <TextField
        label="Address"
        value={formData.address}
        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.address}
        helperText={errors.address}
      />
      <TextField
        label="Password"
        type="password"
        value={formData.password}
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.password}
        helperText={errors.password}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="info" // This applies primary color for the button outline and text
          sx={{
            marginRight: 1,
            borderColor: "info.main",
            color: "info.main",
            "&:hover": {
              backgroundColor: "info.light", // Lighter red background on hover
              borderColor: "info.dark", // Darker red border on hover
            },
          }} // Ensures the border and text are primary color
        >
          {user ? "Update" : "Submit"}
        </Button>

        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: "error.main", // Red color from Material UI's error palette
            borderColor: "error.main", // Red border color
            "&:hover": {
              backgroundColor: "error.light", // Lighter red background on hover
              borderColor: "error.dark", // Darker red border on hover
            },
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

// Define prop types
EditorForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    address: PropTypes.string,
    role: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onUserCreatedOrUpdated: PropTypes.func.isRequired,
};

export default EditorForm;
