import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

const AssignVerifierDataTable = ({ onEdit }) => {
  const [assignVerifiers, setAssignVerifiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssignVerifiers = async () => {
      try {
        const response = await axios.get(
          "https://server.ayurdrishti.com/api/assign-verifiers/getAllAssignVerifiers"
        );

        const formattedData = response.map((verifier, index) => ({
          srNo: index + 1,
          articleTitle: verifier.article?.title || "Untitled",
          status: verifier.status || "NEED_CORRECTION",
          comments: verifier.comment || "No Comment",
          action: (
            <div>
              <IconButton color="primary" onClick={() => onEdit(verifier)}>
                <EditIcon />
              </IconButton>
              <IconButton color="secondary" onClick={() => handleDelete(verifier.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        }));
        setAssignVerifiers(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error.message, error.response || error);
        setError("Error fetching data. Please check the console for details.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssignVerifiers();
  }, [onEdit]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this verifier?")) {
      try {
        await axios.delete(
          `https://server.ayurdrishti.com/api/assign-verifiers/deleteAssignVerifier/${id}`
        );
        setAssignVerifiers(assignVerifiers.filter((verifier) => verifier.id !== id));
      } catch (error) {
        console.error("Error deleting Assign Verifier:", error);
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <DataTable
      table={{
        columns: [
          { Header: "Sr. No", accessor: "srNo" },
          { Header: "Article Name", accessor: "articleTitle" },
          { Header: "Status", accessor: "status" },
          { Header: "Comments", accessor: "comments" },
          { Header: "Action", accessor: "action" },
        ],
        rows: assignVerifiers,
      }}
      isSorted={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

AssignVerifierDataTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default AssignVerifierDataTable;
