import React, { useState } from "react";
import sendPaymentRequest from "./PaymentService";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@mui/material";
import MDTypography from "components/MDTypography";

const PhonepeComponent = () => {
  const fixedAmount = 1;

  const [paymentRequest] = useState({
    amount: fixedAmount,
    paymentStatus: "",
    transactionId: uuidv4().substring(0, 12),
  });

  const makePayment = async (e) => {
    e.preventDefault();
    try {
      const url = await sendPaymentRequest(paymentRequest);
      console.log("Received URL from server:", url);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const statusButtonStyle = () => ({
    borderColor: "green",
    color: "white",
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
  });

  return (
    <div>
      <form onSubmit={makePayment}>
        <Button
          type="submit"
          variant="contained"
          style={statusButtonStyle(paymentRequest.paymentStatus)}
        >
          {paymentRequest.paymentStatus === "PAID" ? "Paid" : "Pay 1 Rs Now"}
        </Button>
      </form>
    </div>
  );
};

export default PhonepeComponent;
