import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdminDashboard from "layouts/admindashboard/components/AdminDashboard";
import { fetchUsers } from "layouts/editorslist/data/fetchUsers";
import { fetchArticleDetails } from "layouts/allarticles/data/articleService";

function Overview() {
  const [editorCount, setEditorCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [articleCount, setArticleCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [publishedArticleCount, setPublishedArticleCount] = useState(0);

  const fetchArticleCount = async () => {
    setLoading(true);
    try {
      const response = await fetchArticleDetails();
      const articles = response || []; // Ensure response data is not null or undefined

      // Option 1: Count all articles
      setArticleCount(articles.length);

      // Option 2: Count only published articles
      const publishedArticles = articles.filter((article) => article.published === "YES");
      setPublishedArticleCount(publishedArticles.length);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchArticleCount();
  }, []);

  const fetchEditorCount = async () => {
    setLoading(true);
    try {
      const response = await fetchUsers();
      const count = response.data.filter((user) => user.role === "EDITOR").length;
      setEditorCount(count);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEditorCount();
  }, []);

  const fetchUserCount = async () => {
    setLoading(true);
    try {
      const response = await fetchUsers();
      const count = response.data.filter((user) => user.role === "USER").length;
      setUserCount(count);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCount();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AdminDashboard
          editorCount={editorCount}
          userCount={userCount}
          articleCount={articleCount}
          publishedArticleCount={publishedArticleCount}
        />
      </MDBox>
      {loading && <MDBox>Loading...</MDBox>}
    </DashboardLayout>
  );
}

export default Overview;
