import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Information from "layouts/about/sections/Information";
import routes from "routes";
import bgImage from "assets/images/bg3.jpg";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedTypography = styled(MKTypography)(({ theme }) => ({
  animation: `${fadeIn} 1s ease-in-out forwards`,
}));

function About() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "75%" },
            padding: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              mx: "auto",
              textAlign: "center",
              padding: { xs: 2, sm: 3, md: 4 },
            }}
          >
            <AnimatedTypography
              variant="h1"
              color="success"
              sx={({ breakpoints }) => ({
                fontWeight: "bold",
                letterSpacing: "0.5px",
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
                fontSize: { xs: "6vw", sm: "4vw", md: "3vw", lg: "2.5vw" },
                lineHeight: { xs: 1.2, sm: 1.3, md: 1.5 },
              })}
            >
              About Ramvilas Foundation&apos;s
            </AnimatedTypography>

            <AnimatedTypography
              variant="body1"
              color="white"
              opacity={0.9}
              mb={3}
              sx={{
                lineHeight: 1.6,
                textAlign: "justify",
                fontWeight: 300,
                letterSpacing: "0.5px",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
                maxWidth: { xs: "90%", sm: "85%", md: "75%", lg: "65%" },
                fontSize: { xs: "4vw", sm: "3vw", md: "1.5vw" },
              }}
            >
              Since 2020, Ramvilas Foundation has empowered communities through quality medical
              education, innovative social initiatives, and the advancement of Ayurveda, positioning
              us at the forefront of positive change.
            </AnimatedTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        sx={{
          textAlign: "center",
          mt: 5,
          mb: -10,
          padding: { xs: 2, md: 4 },
          borderRadius: "8px",
        }}
      >
        <MKTypography
          variant="h2"
          color="secondary.main"
          gutterBottom
          sx={{
            fontWeight: "bold",
            letterSpacing: "0.5px",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
          }}
        >
          Our Core Areas of Focus
        </MKTypography>
        <MKTypography variant="body2" color="text.secondary" sx={{ opacity: 0.9, mb: 3 }}>
          Explore our mission, objectives, and initiatives that drive positive change in healthcare
          and community welfare.
        </MKTypography>
      </MKBox>
      <Information />
      <MKBox pt={6} px={1} mt={-6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default About;
