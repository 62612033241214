import Dashboard from "layouts/dashboard";
import EditorDashboard from "layouts/editordashboard";
import Header from "layouts/admindashboard";
import UserDashboard from "layouts/userdashboard";
import AllArticlesTables from "layouts/allarticles";
import Pay from "layouts/articlespay";
import TransactionTables from "layouts/transaction";
import AissgnTables from "layouts/assignedarticle";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import AddArticlesForm from "layouts/addarticlesform";
import EditorProfile from "layouts/editorprofile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import UserList from "layouts/userlist";
import AdminProfile from "layouts/admin";
import AssignVerifier from "layouts/assignverifierform";
import List from "layouts/editorslist";
import Contact from "layouts/contact/contactmsg";
import ContactData from "layouts/contactdata";
import About from "layouts/about";
import AboutEditor from "layouts/editorteam";
import AboutPeer from "layouts/peerteam";
import AboutHome from "layouts/abouthome";
import Articles from "layouts/articles";
import Author from "layouts/authorguideline";
import Icon from "@mui/material/Icon";

const allRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    roles: [""],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "editordashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/editordashboard",
    component: <EditorDashboard />,
    roles: ["EDITOR"],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "admindashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admindashboard",
    component: <Header />,
    roles: ["ADMIN"],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "userdashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/userdashboard",
    component: <UserDashboard />,
    roles: ["USER"],
  },
  {
    type: "collapse",
    name: "Editor Team",
    key: "editorteam",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/editorteam",
    component: <AboutEditor />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Peer Team",
    key: "peerteam",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/peerteam",
    component: <AboutPeer />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Author Guidelines",
    key: "authorguideline",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/authorguideline",
    component: <Author />,
    roles: ["ALL"],
  },

  {
    type: "collapse",
    name: "About",
    key: "about",
    icon: <Icon fontSize="small">contact</Icon>,
    route: "/about",
    component: <About />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">contact</Icon>,
    route: "/contact",
    component: <Contact />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Contact Data",
    key: "contactdata",
    icon: <Icon fontSize="small">call</Icon>,
    route: "/contactdata",
    component: <ContactData />,
    roles: ["ADMIN"],
  },
  {
    type: "collapse",
    name: "Articles",
    key: "articles",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/articles",
    component: <Articles />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Profile",
    key: "admin",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/admin",
    component: <AdminProfile />,
    roles: ["ADMIN"],
  },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    roles: ["USER"],
  },

  {
    type: "collapse",
    name: "Add Articles",
    key: "addarticlesform",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/addarticlesform",
    component: <AddArticlesForm />,
    roles: ["USER"],
  },
  {
    type: "collapse",
    name: "Profile",
    key: "editorprofile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/editorprofile",
    component: <EditorProfile />,
    roles: ["EDITOR"],
  },

  {
    type: "collapse",
    name: "Users List",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <UserList />,
    roles: ["ADMIN"],
  },
  {
    type: "collapse",
    name: "Editors List",
    key: "editorslist",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/editorslist",
    component: <List />,
    roles: ["ADMIN"],
  },

  {
    type: "collapse",
    name: "All Articles",
    key: "allarticles",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/allarticles",
    component: <AllArticlesTables />,
    roles: ["ALL"],
  },

  {
    type: "collapse",
    name: "Articles",
    key: "articlespay",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/articlespay",
    component: <Pay />,
    roles: ["USER"],
  },

  {
    type: "collapse",
    name: "Assigned verifier",
    key: "assignverifierform",
    icon: <Icon fontSize="small">verified</Icon>,
    route: "/assignverifierform",
    component: <AssignVerifier />,
    roles: ["ADMIN"],
  },

  {
    type: "collapse",
    name: "Transaction",
    key: "transaction",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/transaction",
    component: <TransactionTables />,
    roles: ["ADMIN"],
  },

  {
    type: "collapse",
    name: "Assigned Articles",
    key: "assignedarticle",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: "/assignedarticle",
    component: <AissgnTables />,
    roles: ["EDITOR"],
  },

  {
    type: "collapse",
    name: "Home",
    key: "abouthome",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/abouthome",
    component: <AboutHome />,
    roles: ["ALL"],
  },

  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    roles: [""],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    roles: ["ALL"],
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    roles: ["ALL"],
  },
];

export default allRoutes;
