import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";
import { fetchArticleDetails, updateArticleDetails, downloadArticleFile } from "./articleService"; // Import service
import { FaEdit } from "react-icons/fa";

export default function DataTable() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articles = await fetchArticleDetails(); // Fetch data from service
        const formattedArticles = articles.map((article, index) => ({
          id: article.id,
          srNo: index + 1,
          title: article.title,
          file: article.file,
          verified: article.verified,
          paymentStatus: article.paymentStatus,
          published: article.published,
          publishedDate:
            article.published === "YES"
              ? article.publishedDate || new Date().toLocaleDateString()
              : "N/A",
          action: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px" },
              }}
            >
              <FaEdit style={{ fontSize: "100%" }} />
            </MDTypography>
          ),
        }));
        setRows(formattedArticles);
      } catch (error) {
        console.error("Error fetching articles: ", error);
      }
    };

    fetchArticles();
  }, []);

  const handleToggleVerified = async (index) => {
    const updatedRow = { ...rows[index] };
    updatedRow.verified = updatedRow.verified === "VERIFIED" ? "NOT_VERIFIED" : "VERIFIED";
    updatedRow.publishedDate =
      updatedRow.published === "YES" ? new Date().toLocaleDateString() : null;

    try {
      const updatedArticle = await updateArticleDetails(updatedRow.id, updatedRow); // Call service to update article
      setRows((prevRows) => prevRows.map((row, i) => (i === index ? updatedArticle : row)));
    } catch (error) {
      console.error("Error updating verified status: ", error);
    }
  };

  const handleTogglePaymentStatus = async (index) => {
    const updatedRow = { ...rows[index] };
    updatedRow.paymentStatus = updatedRow.paymentStatus === "PAID" ? "PENDING" : "PAID";
    updatedRow.publishedDate =
      updatedRow.published === "YES" ? new Date().toLocaleDateString() : null;

    try {
      const updatedArticle = await updateArticleDetails(updatedRow.id, updatedRow); // Call service to update article
      setRows((prevRows) => prevRows.map((row, i) => (i === index ? updatedArticle : row)));
    } catch (error) {
      console.error("Error updating payment status: ", error);
    }
  };

  const handleTogglePublished = async (index) => {
    const updatedRow = { ...rows[index] };
    updatedRow.published = updatedRow.published === "YES" ? "NO" : "YES";
    updatedRow.publishedDate =
      updatedRow.published === "YES" ? new Date().toLocaleDateString() : null;

    try {
      const updatedArticle = await updateArticleDetails(updatedRow.id, updatedRow); // Call service to update article
      setRows((prevRows) => prevRows.map((row, i) => (i === index ? updatedArticle : row)));
    } catch (error) {
      console.error("Error updating published status: ", error);
    }
  };

  const handleOpenFile = async (fileName) => {
    try {
      const fileData = await downloadArticleFile(fileName); // Call service to download the file
      const url = window.URL.createObjectURL(new Blob([fileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set filename for download
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the link
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const ViewFileButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleOpenFile(row.original.file)}
      style={{
        borderRadius: "20px",
        margin: "0 5px",
        textTransform: "none",
        color: "green",
        borderColor: "green",
      }}
    >
      View File
    </Button>
  );

  const statusButtonStyle = (status) => ({
    borderColor: status === "PAID" ? "green" : "red",
    color: status === "PAID" ? "green" : "red",
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
  });

  const PaymentStatusButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleTogglePaymentStatus(row.index)}
      style={statusButtonStyle(row.original.paymentStatus)}
    >
      {row.original.paymentStatus === "PAID" ? "Paid" : "Pending"}
    </Button>
  );

  const statusButtonnStyle = (status) => ({
    borderColor: status === "VERIFIED" ? "green" : "red",
    color: status === "VERIFIED" ? "green" : "red",
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
  });

  const VerifiedButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleToggleVerified(row.index)}
      style={statusButtonnStyle(row.original.verified)}
    >
      {row.original.verified === "VERIFIED" ? "Verified" : "Not Verified"}
    </Button>
  );

  const sttatusButtonnStyle = (status) => ({
    borderColor: status === "YES" ? "green" : "red",
    color: status === "YES" ? "green" : "red",
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
  });

  const PublishedButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleTogglePublished(row.index)}
      style={sttatusButtonnStyle(row.original.published)}
    >
      {row.original.published === "YES" ? "Yes" : "No"}
    </Button>
  );

  const PublishedDate = ({ row }) => (
    <MDTypography variant="caption" color="text" fontWeight="medium">
      {row.original.published === "YES" ? row.original.publishedDate : "N/A"}
    </MDTypography>
  );

  VerifiedButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        verified: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PaymentStatusButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        paymentStatus: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PublishedButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        published: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PublishedDate.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        published: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  ViewFileButton.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  return {
    columns: [
      { Header: "Sr. No", accessor: "srNo", width: "10%", align: "center" },
      { Header: "Title", accessor: "title", align: "left" },
      {
        Header: "View File",
        accessor: "file",
        align: "center",
        Cell: ViewFileButton,
      },
      {
        Header: "Verified",
        accessor: "verified",
        align: "center",
        Cell: VerifiedButton,
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        align: "center",
        Cell: PaymentStatusButton,
      },
      {
        Header: "Published",
        accessor: "published",
        align: "center",
        Cell: PublishedButton,
      },
      {
        Header: "Published Date",
        accessor: "publishedDate",
        align: "center",
        Cell: PublishedDate,
      },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
  };
}
