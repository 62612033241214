import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox"; // Assuming this is your custom component
import MKTypography from "components/MKTypography"; // Assuming this is your custom component

function DefaultAboutInfoCard({ color, icon, title, description, action }) {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleCardClick = () => {
    if (action?.route) {
      navigate(action.route); // Navigate to the specified route
    }
  };

  return (
    <Card
      onClick={handleCardClick}
      style={{
        cursor: "pointer",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
        borderRadius: "8px", // Rounded corners for a better look
      }}
    >
      <MKBox p={2} mx={3} display="flex" justifyContent="center">
        <MKBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color} // Background color
          color="white" // Icon color
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default" style={{ color: "white" }}>
            {icon}
          </Icon>{" "}
          {/* Set icon color */}
        </MKBox>
      </MKBox>
      <MKBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MKTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MKTypography>
        {description && (
          <MKTypography
            variant="body2"
            color="text"
            fontWeight="regular"
            sx={{ textAlign: "left", ml: 2 }}
          >
            {/* Updated to display bullet points */}
            <ul style={{ paddingLeft: "20px" }}>
              {description.split(". ").map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </MKTypography>
        )}
        {description && action ? <Divider /> : null}
        {/* Show action link if present */}
        {action && action.label && (
          <MKTypography
            variant="body2"
            color={action.color || "primary"}
            onClick={() => handleCardClick()} // Navigate on click
            style={{ cursor: "pointer", marginTop: "1rem" }}
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of DefaultAboutInfoCard
DefaultAboutInfoCard.defaultProps = {
  color: "info",
  description: "",
  action: null, // Set default for action
};

// Typechecking props for the DefaultAboutInfoCard
DefaultAboutInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired, // Updated to accept React nodes for icons
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    route: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default DefaultAboutInfoCard;
