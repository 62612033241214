import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EditorDashboard from "layouts/editordashboard/components/EditorDashboard";
import { fetchArticleDetails } from "layouts/allarticles/data/articleService";

function Overview() {
  const [articleCount, setArticleCount] = useState(0);
  const [verifiedArticleCount, setVerifiedArticleCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchArticleCount = async () => {
    setLoading(true);
    try {
      const response = await fetchArticleDetails(); // Fetch articles from the service
      const articles = response || []; // Ensure response data is not null or undefined

      // Count all articles (total number of articles)
      setArticleCount(articles.length);

      // Count only verified articles
      const verifiedArticles = articles.filter((article) => article.verified === "VERIFIED");
      setVerifiedArticleCount(verifiedArticles.length);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleCount(); // Fetch article counts when the component mounts
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox>Loading...</MDBox> // Show loading message while fetching
        ) : (
          <EditorDashboard
            articleCount={articleCount}
            verifiedArticleCount={verifiedArticleCount}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
