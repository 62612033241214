import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Team from "layouts/peerteam/sections/Team";
import routes from "routes";
import bgImage from "assets/images/people-taking-part-high-protocol-event.jpg";

function AboutEditor() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight={{ xs: "500px" }}
        width="100%"
        pt={{ xs: 2, sm: 2, md: 4, lg: 5 }}
        pb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                fontSize: size["2xl"],
                [breakpoints.up("md")]: {
                  fontSize: size["3xl"],
                },
                [breakpoints.up("lg")]: {
                  fontSize: size["4xl"],
                },
              })}
            >
              Meet Our Esteemed Team
            </MKTypography>
            <MKTypography
              variant="h4"
              color="white"
              opacity={0.9}
              mt={1}
              sx={({ breakpoints, typography: { size } }) => ({
                fontSize: size.lg,
                [breakpoints.up("md")]: {
                  fontSize: size.xl,
                },
                [breakpoints.up("lg")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Dedicated to Excellence in Research and Academia
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
              sx={({ breakpoints }) => ({
                fontSize: "1rem",
                [breakpoints.up("md")]: {
                  fontSize: "1.125rem",
                },
              })}
            >
              Collaborating to foster innovation and enrich knowledge within our fields.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: { xs: 1, md: 3 },
          mx: { xs: 1.5, lg: 2 },
          mt: -4,
          mb: 3,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Team />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default AboutEditor;
