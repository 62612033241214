import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function HorizontalTeamCard({ image, name, position, description }) {
  return (
    <Card sx={{ mt: 2, p: 2 }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3} sx={{ mt: { xs: -2, sm: -4 }, ml: { xs: 0, sm: -1 } }}>
          <MKBox width="100%" display="flex" justifyContent="center">
            <MKBox
              component="img"
              src={image}
              alt={name}
              width={{ xs: "80px", sm: "100px" }} // Smaller width for mobile
              height={{ xs: "80px", sm: "100px" }}
              borderRadius="50%"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MKBox px={{ xs: 1, sm: 2 }} lineHeight={1}>
            <MKTypography variant="h5" sx={{ mb: 0.2 }}>
              {name}
            </MKTypography>
            <MKTypography variant="h6" color={position.color} sx={{ mb: 0.2 }}>
              {position.label}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {description}
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
// HorizontalTeamCard.propTypes = {
//   image: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   position: PropTypes.shape({
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "light",
//     ]).isRequired,
//     label: PropTypes.string.isRequired,
//   }).isRequired,
//   description: PropTypes.string.isRequired,
// };

HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Allows both string and JSX
};

export default HorizontalTeamCard;
