import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://server.ayurdrishti.com/api/contact",
});

const fetchContacts = async () => {
  try {
    const response = await axiosInstance.get("/messages");
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts", error);
    throw error;
  }
};

const deleteContact = async (id) => {
  try {
    await axiosInstance.delete(`/messages/${id}`);
  } catch (error) {
    console.error("Error deleting contact:", error);
    throw error;
  }
};

export { fetchContacts, deleteContact };
