import axios from "axios";

const api = axios.create({
  baseURL: "https://server.ayurdrishti.com/rest/api",
});

export const createArticle = async (articleData, file) => {
  try {
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    const { file: _, ...articleWithoutFile } = articleData;

    formData.append("article", JSON.stringify(articleWithoutFile));

    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found.");
    }

    const response = await api.post("/articles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorisation: `Token ${token}`,
      },
    });

    console.log("Response:", response);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response?.data || "An error occurred");
    } else {
      throw new Error(error.message || "An error occurred while uploading the article");
    }
  }
};

export default { createArticle };
