// src/layouts/editorslist/index.js
import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EditorList from "layouts/editorslist/data/EditorList";
import MDBox from "components/MDBox";

const EditorsListPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <EditorList />
    </DashboardLayout>
  );
};

export default EditorsListPage;
