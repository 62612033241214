import React, { useState } from "react";
import { Card, Grid, TextField, Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/60111.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

function Header() {
  const [userName, setUserName] = useState(localStorage.getItem("name") || "User");
  const [userEmail, setUserEmail] = useState(localStorage.getItem("email") || "user@example.com");
  const [tabValue, setTabValue] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("name", userName);
    localStorage.setItem("email", userEmail);
    alert("Information updated successfully!");
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {userName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {userEmail}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>

        {tabValue === 0 && (
          <Card
            sx={{
              mt: 4,
              p: { xs: 2, sm: 3 },
              maxWidth: "1200px",
              mx: "auto",
              backgroundColor: "#f5f5f5",
              width: "100%",
              boxShadow: (theme) => theme.shadows[4],
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", sm: "500px" },
                mx: "auto",
                mt: -2,
                backgroundColor: (theme) => theme.palette.info.main,
                borderRadius: "8px",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: (theme) => theme.shadows[4],
              }}
            >
              <MDTypography variant="h6" color="white">
                Private Information
              </MDTypography>
            </MDBox>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ p: { xs: 1, sm: 2 } }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} textAlign={{ xs: "center", sm: "right" }}>
                  <Button type="submit" variant="contained" color="primary">
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        )}
      </Card>
    </MDBox>
  );
}

export default Header;
