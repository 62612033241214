import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import axios from "axios";
import { Snackbar } from "@mui/material";

function Cover() {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    country: "",
    email: "",
    password: "",
    repeatPassword: "",
    c1: false,
    c2: false,
    c3: false,
  });

  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  // Helper function for validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error when user modifies the field
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, contact, address, country, email, password, repeatPassword } = formData;
    let formErrors = {};

    // Validation logic
    if (!name) formErrors.name = "Name is required.";
    if (!contact) formErrors.contact = "Contact is required.";
    else if (contact.length !== 10 || isNaN(contact)) {
      formErrors.contact = "Contact must be exactly 10 digits.";
    }

    if (!address) formErrors.address = "Address is required.";
    if (!country) formErrors.country = "Country is required.";
    if (!email) formErrors.email = "Email is required.";
    else if (!validateEmail(email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!password) formErrors.password = "Password is required.";
    else if (!validatePassword(password)) {
      formErrors.password =
        "Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character.";
    }

    if (!repeatPassword) formErrors.repeatPassword = "Please confirm your password.";
    else if (password !== repeatPassword) {
      formErrors.repeatPassword = "Passwords do not match.";
    }

    // If there are errors, set them and return early
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const requestBody = {
      ...formData,
      c1: formData.c1 ? "Y" : "N",
      c2: formData.c2 ? "Y" : "N",
      c3: formData.c3 ? "Y" : "N",
    };
    //https://server.ayurdrishti.com/api/users
    try {
      await axios.post("https://server.ayurdrishti.com/api/users", requestBody);
      setSnackbarMessage("Sign-up successful!");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/authentication/sign-in");
      }, 1000);
    } catch (error) {
      setSnackbarMessage("Error submitting form. Please try again.");
      setOpenSnackbar(true);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        px={2}
        sx={{ flexDirection: "column" }}
      >
        <Card sx={{ width: { xs: "90%", sm: "70%", md: "600px" }, mb: 4 }}>
          {" "}
          {/* Added mb: 4 for bottom space */}
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Join us today
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your information to get started with your account
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox display="flex" flexWrap="wrap" mb={3} sx={{ gap: 1 }}>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <MDTypography variant="caption" color="error">
                      {errors.name}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Contact"
                    name="contact"
                    variant="standard"
                    fullWidth
                    value={formData.contact}
                    onChange={handleChange}
                  />
                  {errors.contact && (
                    <MDTypography variant="caption" color="error">
                      {errors.contact}
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox display="flex" flexWrap="wrap" mb={3} sx={{ gap: 1 }}>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Address"
                    name="address"
                    variant="standard"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <MDTypography variant="caption" color="error">
                      {errors.address}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Country"
                    name="country"
                    variant="standard"
                    fullWidth
                    value={formData.country}
                    onChange={handleChange}
                  />
                  {errors.country && (
                    <MDTypography variant="caption" color="error">
                      {errors.country}
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  variant="standard"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <MDTypography variant="caption" color="error">
                    {errors.email}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type="password"
                  label="Password"
                  name="password"
                  variant="standard"
                  fullWidth
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <MDTypography variant="caption" color="error">
                    {errors.password}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type="password"
                  label="Repeat Password"
                  name="repeatPassword"
                  variant="standard"
                  fullWidth
                  value={formData.repeatPassword}
                  onChange={handleChange}
                />
                {errors.repeatPassword && (
                  <MDTypography variant="caption" color="error">
                    {errors.repeatPassword}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3} display="flex" alignItems="center">
                <Checkbox name="c1" checked={formData.c1} onChange={handleChange} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={() => setFormData({ ...formData, c1: !formData.c1 })}
                >
                  &nbsp;&nbsp;I agree to the&nbsp;
                </MDTypography>
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Terms and Conditions
                </MDTypography>
              </MDBox>
              <MDBox mb={3} display="flex" alignItems="center">
                <Checkbox name="c2" checked={formData.c2} onChange={handleChange} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={() => setFormData({ ...formData, c2: !formData.c2 })}
                >
                  &nbsp;&nbsp;I want to receive newsletter
                </MDTypography>
              </MDBox>
              <MDBox mb={3} display="flex" alignItems="center">
                <Checkbox name="c3" checked={formData.c3} onChange={handleChange} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={() => setFormData({ ...formData, c3: !formData.c3 })}
                >
                  &nbsp;&nbsp;I want to receive offers and promotions
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Sign Up
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Snackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          autoHideDuration={3000}
        />
      </MDBox>
    </CoverLayout>
  );
}

export default Cover;
