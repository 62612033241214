import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { fetchUsers, updateUser } from "layouts/userlist/data/fetchUsers";

function UserList() {
  const [users, setUsers] = useState([]);

  const fetchUsersData = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.data.filter((user) => user.role === "USER"));
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleStatusClick = async (user) => {
    const newStatus = user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    const updatedUser = { ...user, status: newStatus };

    try {
      await updateUser(user.id, updatedUser);
      fetchUsersData();
    } catch (error) {
      console.error("Error updating user status", error);
    }
  };

  const columns = [
    { Header: "Name", accessor: "name", isSorted: true, isSortedDesc: false },
    { Header: "Email", accessor: "email", isSorted: true, isSortedDesc: false },
    { Header: "Contact", accessor: "contact", isSorted: true, isSortedDesc: false },
    { Header: "Address", accessor: "address", isSorted: true, isSortedDesc: false },
    { Header: "Country", accessor: "country", isSorted: true, isSortedDesc: false },
    { Header: "Role", accessor: "role", isSorted: true, isSortedDesc: false },
    {
      Header: "Status",
      accessor: "status",
      isSorted: true,
      isSortedDesc: false,
      Cell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleStatusClick(row.original)}
          sx={{
            backgroundColor: row.original.status === "ACTIVE" ? "green" : "red",
            color: "white",
            "&:hover": {
              backgroundColor: row.original.status === "ACTIVE" ? "darkgreen" : "darkred",
            },
            padding: "6px 12px",
            borderRadius: "4px",
            textTransform: "none",
          }}
        >
          {row.original.status}
        </Button>
      ),
    },
  ];

  const rows = users.map((user) => ({
    id: user.id,
    name: user.name,
    email: user.email,
    contact: user.contact,
    address: user.address,
    country: user.country,
    role: user.role,
    status: user.status,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white" align="center">
                  Users Overview
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

UserList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      isSorted: PropTypes.bool,
      isSortedDesc: PropTypes.bool,
      Cell: PropTypes.func,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }),
};

export default UserList;
