import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CustomCard from "examples/Cards/CustomCard";
import team1 from "assets/images/exploreservices.avif";

function Services() {
  const firstCardLinks = [
    { text: "User Registration and Login", url: "/register" },
    { text: "Paper Submission Portal", url: "/submission" },
    { text: "Submission Confirmation", url: "/confirmation" },
    { text: "Editor Assignment", url: "/editor-assignment" },
    { text: "Review Process Tracking", url: "/review-tracking" },
  ];

  const secondCardLinks = [
    { text: "Editorial Review", url: "/editorial-review" },
    { text: "Revisions and Resubmissions", url: "/revisions" },
    { text: "Final Approval", url: "/final-approval" },
    { text: "Formatting and Proofreading", url: "/formatting" },
    { text: "Publication and Dissemination", url: "/publication" },
  ];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="light"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      mt={-7}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3">Explore Services</MKTypography>
            <MKTypography variant="body2" opacity={0.8}>
              We offer a range of services designed to support you through the entire publication
              process. From user registration to editorial review, our team ensures a seamless
              experience. Explore the services below to learn more about each step.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MKBox mb={5}>
              <CustomCard title="Services Process" links={firstCardLinks} />
            </MKBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MKBox display="flex" justifyContent="center" alignItems="center" mb={5}>
              <img
                src={team1}
                alt="Editorial Team"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MKBox mb={5}>
              <CustomCard title="Publication Process" links={secondCardLinks} />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Services;
