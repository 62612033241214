import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserDashboard from "layouts/userdashboard/components/UserDashboard";
import { fetchArticleDetails } from "layouts/articlespay/phonepe/articleService"; // Import the updated service

function Overview() {
  const [articleCount, setArticleCount] = useState(0);
  const [publishedArticleCount, setPublishedArticleCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem("uid"); // Get the userId from localStorage

  const fetchArticleCount = async () => {
    setLoading(true);
    try {
      if (userId) {
        const articles = await fetchArticleDetails(userId); // Pass userId to fetch articles for that user
        const publishedArticles = articles.filter((article) => article.published === "YES");

        // Set the article counts
        setArticleCount(articles.length);
        setPublishedArticleCount(publishedArticles.length);
      } else {
        console.error("User not logged in or userId missing");
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleCount(); // Fetch article count when the component mounts
  }, [userId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox>Loading...</MDBox> // Show loading text while fetching data
        ) : (
          <UserDashboard
            articleCount={articleCount}
            publishedArticleCount={publishedArticleCount}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
