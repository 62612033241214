import React, { useState } from "react";
import { Card, Grid, TextField, Button, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const AssignVerifierForm = () => {
  const [selectedSubmission, setSelectedSubmission] = useState("");
  const [selectedVerifier, setSelectedVerifier] = useState("");

  return (
    <>
      <Card
        sx={{
          mt: 4,
          p: 0,
          maxWidth: "1200px",
          mx: "auto",
          backgroundColor: "#f5f5f5",
          width: "100%",
          boxShadow: (theme) => theme.shadows[4],
        }}
      >
        <MDBox
          sx={{
            width: "100%",
            maxWidth: "500px",
            mx: "auto",
            mt: -2,
            backgroundColor: (theme) => theme.palette.info.main,
            borderRadius: "8px",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: (theme) => theme.shadows[4],
          }}
        >
          <MDTypography variant="h6" color="white">
            Assign Verifier
          </MDTypography>
        </MDBox>

        <form>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Status"
                variant="outlined"
                select
                value={selectedSubmission || ""}
                onChange={(e) => setSelectedSubmission(e.target.value)}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  IconComponent: () => <span style={{ marginLeft: "8px" }}>▼</span>,
                }}
                InputProps={{
                  style: {
                    height: "40px",
                    padding: "8px 14px",
                  },
                }}
              >
                <MenuItem value="submission1">Verified</MenuItem>
                <MenuItem value="submission2">Assigned</MenuItem>
                <MenuItem value="submission3">Need Correction</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="input-msgToPublisher"
                label="Comments"
                placeholder="Enter Comments Here..."
                multiline
                rows={2}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    height: "70px",
                    padding: "8px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="outlined"
                color="info" // This applies primary color for the button outline and text
                sx={{
                  marginRight: 1,
                  borderColor: "info.main",
                  color: "info.main",
                  "&:hover": {
                    backgroundColor: "info.light", // Lighter red background on hover
                    borderColor: "info.dark", // Darker red border on hover
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
};

export default AssignVerifierForm;
