import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";

export default function DataTable() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        const response = await axios.get("https://server.ayurdrishti.com/api/article-details");
        const articles = response.data.map((article, index) => ({
          id: article.id, // Ensure you have a unique identifier
          srNo: index + 1,
          title: article.title,
          // file: article.file,
          verified: article.verified,
          publishedDate:
            article.verified === "VERIFIED"
              ? article.publishedDate || new Date().toLocaleDateString()
              : "N/A",
          action: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              Edit
            </MDTypography>
          ),
        }));
        setRows(articles);
      } catch (error) {
        console.error("Error fetching articles: ", error);
      }
    };

    fetchArticleDetails();
  }, []);

  const handleToggleVerified = (index) => {
    const updatedRow = { ...rows[index] }; // Create a copy to avoid mutation

    // Toggle the verified status
    updatedRow.verified = updatedRow.verified === "VERIFIED" ? "NOT_VERIFIED" : "VERIFIED";

    axios
      .put(`https://server.ayurdrishti.com/api/article-details/${updatedRow.id}`, {
        verified: updatedRow.verified,
      })
      .then((response) => {
        // Update only the verified status in the rows
        setRows((prevRows) =>
          prevRows.map((row, i) =>
            i === index ? { ...row, verified: response.data.verified } : row
          )
        );
      })
      .catch((error) => console.error("Error updating verified status: ", error));
  };

  // const handleOpenFile = async (fileName) => {
  //   try {
  //     const response = await axios.get(`https://server.ayurdrishti.com/api/file/${fileName}`, {
  //       responseType: "blob", // Important for file download
  //     });

  //     // Create a URL for the file
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", fileName); // Set the filename for download
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove(); // Clean up the link
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  // const ViewFileButton = ({ row }) => (
  //   <Button
  //     variant="outlined"
  //     onClick={() => handleOpenFile(row.original.file)}
  //     style={{
  //       borderRadius: "20px",
  //       margin: "0 5px",
  //       textTransform: "none",
  //       color: "green",
  //       borderColor: "green",
  //     }}
  //   >
  //     View File
  //   </Button>
  // );

  const statusButtonStyle = (status) => ({
    borderColor: status === "VERIFIED" ? "green" : "red",
    color: status === "VERIFIED" ? "green" : "red",
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
  });

  const VerifiedButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleToggleVerified(row.index)}
      style={statusButtonStyle(row.original.verified)}
    >
      {row.original.verified === "VERIFIED" ? "Verified" : "Not Verified"}
    </Button>
  );

  const PublishedDate = ({ row }) => (
    <MDTypography variant="caption" color="text" fontWeight="medium">
      {row.original.verified === "VERIFIED" ? row.original.publishedDate : "N/A"}
    </MDTypography>
  );

  VerifiedButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        verified: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PublishedDate.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        verified: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  // ViewFileButton.propTypes = {
  //   row: PropTypes.shape({
  //     original: PropTypes.shape({
  //       file: PropTypes.string.isRequired,
  //     }).isRequired,
  //   }).isRequired,
  // };

  return {
    columns: [
      { Header: "Sr. No", accessor: "srNo", width: "10%", align: "center" },
      { Header: "Title", accessor: "title", align: "left" },
      // {
      //   Header: "View File",
      //   accessor: "file",
      //   align: "center",
      //   Cell: ViewFileButton,
      // },
      {
        Header: "Status",
        accessor: "verified",
        align: "center",
        Cell: VerifiedButton,
      },
      {
        Header: "Published Date",
        accessor: "publishedDate",
        align: "center",
        Cell: PublishedDate,
      },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
  };
}
