import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import HorizontalExploreCardPeer from "examples/Cards/TeamCards/HorizontalExploreCardPeer";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="secondary"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-0.5}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MDTypography
              fontWeight="bold"
              sx={{
                fontSize: {
                  sm: "2rem",
                },
                textAlign: "left",
                color: "#FFDD44",
              }}
            >
              Peer Team Members
            </MDTypography>
            <MDTypography
              fontWeight="medium"
              sx={{
                fontSize: {
                  xs: "0.875rem",
                },
                textAlign: "left",
                color: "#D3D3D3",
                opacity: 0.8,
              }}
            >
              The peer team members are dedicated to fostering research and academic growth within
              their areas of expertise. Their contributions bring deep insights and specialized
              knowledge, ensuring that every step of the process upholds the highest standards of
              quality and excellence.
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* Card 1 */}
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalExploreCardPeer
                name="Dr Sanjay B Khedekar"
                position={{ color: "info", label: "Associate Professor" }}
                description={
                  <>
                    <div>MD PhD Rasashatra</div>
                    <div>
                      <strong>Institution:</strong> AIIA Goa
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
          {/* Card 2 */}
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalExploreCardPeer
                name="Dr Chandrashekhar Y Jagtap"
                position={{ color: "info", label: "Senior Research Officer" }}
                description={
                  <>
                    <div>MD PhD Rasashastra</div>
                    <div>
                      <strong>Institution:</strong> CARI, Jhansi, Uttar Pradesh
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
          {/* Additional Cards */}
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalExploreCardPeer
                name="Dr Divya D Sawant"
                position={{ color: "info", label: "Senior Research Fellow" }}
                description={
                  <>
                    <div>MS Prasuti Streerog</div>
                    <div>
                      <strong>Institution:</strong> AIIA Goa
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalExploreCardPeer
                name="Dr Ankush H Gunjal"
                position={{ color: "info", label: "Professor" }}
                description={
                  <>
                    <div>MD PhD Kayachikitsa</div>
                    <div>
                      <strong>Institution:</strong> SMBT Ayurved College, Igatpuri, Maharashtra
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mb={1}>
              <HorizontalExploreCardPeer
                name="Dr Avi Fursule"
                position={{ color: "info", label: "MD Dravyaguna" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> New Delhi
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
