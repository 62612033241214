import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://server.ayurdrishti.com/api",
});

const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get("/users");
    return response;
  } catch (error) {
    console.error("Error fetching users", error);
    throw error;
  }
};
const createUser = async (user) => {
  try {
    const response = await axiosInstance.post("/users", user);
    return response.data;
  } catch (error) {
    console.error("Error saving user:", error);
    throw error;
  }
};

const updateUser = async (id, user) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, user);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

const deleteUser = async (id) => {
  try {
    await axiosInstance.delete(`/users/${id}`);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export { fetchUsers, createUser, updateUser, deleteUser };
