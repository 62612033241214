import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const CustomAuthorCard = ({ title, links = [] }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#001f3f",
        marginBottom: 2,
        width: { xs: "100%", sm: 450 }, // Responsive width
        boxShadow: "0px 9px 30px rgba(0, 0, 0, 0.9)",
        borderRadius: 0,
      }}
    >
      <CardContent
        sx={{
          color: "#fff",
          backgroundColor: "#001f3f",
          padding: { xs: 2, sm: 3 }, // Responsive padding
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {links.map((link, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 1,
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "grey",
                },
              }}
            >
              <Box
                onClick={link.onClick}
                sx={{
                  textDecoration: "none",
                  color: "#fff",
                  flexGrow: 1,
                  cursor: "pointer",
                  transition: "color 0.3s, transform 0.3s",
                  "&:hover": {
                    color: "#00a65a",
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Typography variant="body1">{link.text}</Typography>
              </Box>
              <ArrowForward sx={{ color: "#00a65a" }} />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

// Define prop-types for validation
CustomAuthorCard.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default CustomAuthorCard;
