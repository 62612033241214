import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";

const CustomCard = ({ title, links = [] }) => {
  return (
    <Card sx={{ backgroundColor: "#001f3f", marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" color="white" gutterBottom>
          {title}
        </Typography>
        {/* Map through links array and display each link */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {links.map((link, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link
                to={link.url}
                style={{
                  textDecoration: "none",
                  color: "white",
                  flexGrow: 1,
                  transition: "color 0.3s, transform 0.3s", // Animation properties
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = "#00a65a"; // Change color on hover
                  e.currentTarget.style.transform = "scale(1.05)"; // Slightly enlarge
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = "white"; // Reset color
                  e.currentTarget.style.transform = "scale(1)"; // Reset size
                }}
              >
                <Typography variant="body1">{link.text}</Typography>
              </Link>
              <ArrowForward sx={{ color: "white" }} />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

// Define prop-types for validation
CustomCard.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default CustomCard;
