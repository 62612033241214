import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
import { useMediaQuery } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

function DefaultNavbarMobile({ open, close, light }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if screen size is mobile
  const width = isMobile ? "90vw" : "auto"; // Set width to 90vw for mobile, auto for larger screens

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{
        style: {
          width: width,
          maxWidth: "40vw",
          maxHeight: "70vh",
          overflowY: "auto",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "right",
          backgroundColor: "rgba(56, 142, 60, 0.7)", // Green background
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)", // Optional: keep this for shadow effect
          border: "none", // Remove any border
          borderRadius: "2px",
        },
      }}
      PaperProps={{
        style: {
          border: "none", // Remove border from the Menu paper
          backgroundColor: "transparent", // Set transparent background to avoid white space
        },
      }}
    >
      <MDBox
        px={0}
        py={0}
        sx={{
          backgroundColor: "rgba(56, 142, 60, 0.7)",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
          borderRadius: "2px",
          padding: "0px",
        }}
      >
        <DefaultNavbarLink icon="home" name="Home" route="/abouthome" />
        <DefaultNavbarLink icon="info" name="About Us" route="/about" />
        <DefaultNavbarLink icon="person" name="Editorial Team" route="/editorteam" />
        <DefaultNavbarLink icon="person" name="Peer Team" route="/peerteam" />
        <DefaultNavbarLink icon="person" name="Author Guidelines" route="/authorguideline" />
        <DefaultNavbarLink icon="article" name="Articles" route="/articles" />
        <DefaultNavbarLink icon="person" name="Profile" route="/profile" />
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
  light: PropTypes.bool,
};

// Default props
DefaultNavbarMobile.defaultProps = {
  light: false,
};

export default DefaultNavbarMobile;
