import React, { useState } from "react";
import { Container, Grid, Button, Box, Card, TextField, Typography } from "@mui/material";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ArticleCard from "./ArticleCard";
import MDBox from "components/MDBox";
import bgImage from "assets/images/bg-about-us.jpeg";

import article1 from "assets/drpdf/article1.pdf";
import article2 from "assets/drpdf/article2.pdf";
import article3 from "assets/drpdf/article3.pdf";
import article4 from "assets/drpdf/article4.pdf";
import article5 from "assets/drpdf/article5.pdf";

const staticArticles = [
  {
    title: "A SINGLE ARM STUDY ON ROLE OF MADHU ON OBESITY W.S.R. LIPID PROFILE",
    journal: "Vol. 1 Issue 1",
    file: article1,
    size: 2500000, // 2.5 MB
    details: {
      type: "Research Article",
      journal: "Vol. 1 Issue 1",
      author: "Dr Bhujbal Arun",
      articleReceived: "11/9/2024",
      acceptedOn: "11/11/2024",
    },
  },
  {
    title: "FORMULATION OF ‘RASMANIKYA’ AND STUDY ITS ANTIMICROBIAL ACTIVITY",
    journal: "Vol. 1 Issue 1",
    file: article2,
    size: 3200000,
    details: {
      type: "Research Article",
      journal: "Vol. 1 Issue 1",
      author: "Dr Gawade Krupa",
      articleReceived: "14/10/2024",
      acceptedOn: "11/11/2024",
    },
  },
  {
    title:
      "EFFICACY OF HEARTFULNESS -MEDITATION ON ACADEMIC IMPROVEMENT OF FIRST YEAR BAMS STUDENTS – AN INTERVENTIONAL STUDY",
    journal: "Vol. 1 Issue 1",
    file: article3,
    size: 2800000,
    details: {
      type: "Research Article",
      journal: "Vol. 1 Issue 1",
      author: "Dr Dande Bhavana",
      articleReceived: "11/09/2024 ",
      acceptedOn: "11/11/2024",
    },
  },
  {
    title:
      "Randomized Controlled Clinical Study\nTO DETERMINE THE EFFICACY OF AATARUSHAKADI KWATH IN THROMBOCYTOPENIA W.S.R. TO MALARIA",
    journal: "Vol. 1 Issue 1",
    file: article4,
    size: 3500000,
    details: {
      type: "Research Article",
      journal: "Vol. 1 Issue 1",
      author: "Dr Shinde Kaveri",
      articleReceived: "11/09/2024",
      acceptedOn: "11/11/2024",
    },
  },
  {
    title: " STUDY OF WATER INTAKE WITH RESPECT TO HEALTH",
    journal: "Vol. 1 Issue 1",
    file: article5,
    size: 4000000,
    details: {
      type: "Research Article",
      journal: "Vol. 1 Issue 1",
      author: "Dr Sharma Nilesh",
      articleReceived: "11/10/2024",
      acceptedOn: "11/11/2024",
    },
  },
];

const Articles = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleDownloadFile = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", file.split("/").pop());
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const filteredArticles = staticArticles.filter((article) =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MDBox position="relative">
        <MKBox
          minHeight={{ xs: "400px", sm: "500px" }}
          width="100%"
          pt={{ xs: 8, sm: 10, md: 12 }}
          pb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Container
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
              paddingTop: { xs: "50px", md: "100px" },
            }}
          >
            <MKTypography variant="h1" color="white">
              Articles
            </MKTypography>
            <MKTypography variant="h4" color="white" opacity={0.9} mt={1}>
              Stay Informed, Stay Engaged
            </MKTypography>
          </Container>
        </MKBox>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "saturate(200%) blur(30px)",
          }}
        >
          <MKBox mb={3} mt={5} display="flex" flexDirection="column" alignItems="center">
            <MKTypography variant="h3" mb={1}>
              Articles
            </MKTypography>
            <TextField
              label="Search Articles"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ maxWidth: { xs: "100%", sm: "500px", lg: "1000px" } }}
            />
          </MKBox>

          <Grid container spacing={3} justifyContent="center">
            {filteredArticles.map((article, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ArticleCard
                  title={article.title}
                  journal={article.details.journal}
                  file={article.file} // Pass the file prop to ArticleCard
                  handleDownloadFile={handleDownloadFile} // Pass the handleDownloadFile function
                />
                <Box sx={{ textAlign: "right" }}>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    <strong>Type:</strong> {article.details.type}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    <strong>Author:</strong> {article.details.author}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    <strong>Article Received on:</strong> {article.details.articleReceived}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    <strong>Accepted on:</strong> {article.details.acceptedOn}
                  </Typography>

                  <Button
                    variant="text"
                    onClick={() => handleDownloadFile(article.file)}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      "&:hover": { color: "#0984e3" },
                    }}
                  >
                    Read More &rarr;
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </MDBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
};

export default Articles;
